<template>
  <PageLoader v-if="getLoading" />
  <div
    v-else
    class="app-admin-page application-page product-form"
  >
    <AdminCard
      class="application-input-theme-login mb-8-a"
      theme="client"
      :title="(isEdit ? 'productForm.edit' : 'productForm.creation')"
    >
      <div class="product-form__wrap">
        <div class="product-form__item application-input-wrap">
          <div class="application-input-title">
            {{ $t("productForm.name") }}
          </div>
          <AppInput
            :value="model.title"
            :error="!!getErrorMessage('title')"
            class="application-input"
            type="text"
            size="large"
            @input="onModelInput('title', $event)"
          />
          <div class="product-form__error application-input-error">
            {{ getErrorMessage('title') }}
          </div>
        </div>
        <div class="product-form__item application-input-wrap">
          <div class="application-input-title">
            {{ $t("productForm.option") }}
          </div>
          <AppTreeSelect
            :value="model.productOptions"
            class="w-100 mb-2-a"
            hide-item-action
            item-value="value"
            item-key="name"
            :options="options"
            :flatten-items="options"
            @input="onModelInput('productOptions', $event)"
          />
        </div>

        <ShiftLayoutAppearAnimation
          class="w-100"
          :visible="!!selectedOptions.length"
          height="58"
        >
          <div class="product-form__item product-form__item--large tw-mb-4">
            <div class="block-wrap block-wrap--offset-small block-wrap--nowrap tw-items-start">
              <div
                class="tw-flex tw-items-center tw-pr-4 tw-pt-2 tw-shrink-0 tw-text-base tw-leading-5 tw-font-secondary"
              >
                {{ $t("common.form.selectedOptions") }}
              </div>
              <div>
                <DragList
                  class="block-wrap block-wrap--offset-small"
                  :items="selectedOptions"
                  item-value="value"
                  @sort="onOptionsSort"
                  #default="{item}"
                >
                  <div
                    class="filter-pill"
                    @click="selectedOptionsInput(item.value)"
                  >
                    <AppIcon
                      class="filter-pill__icon"
                      :icon="getOptionDescription(item).icon"
                    />
                    {{ item.name }}
                    <AppIcon
                      class="filter-pill__close"
                      icon="close"
                    />
                  </div>
                </DragList>
              </div>
              <div
                v-if="selectedOptions.length > 1"
                class="tw-flex tw-items-center tw-pl-2 tw-pt-2 tw-shrink-0"
              >
                <div
                  class="tw-text-base tw-font-secondary tw-leading-5 link-item link-item--no-underline spacer-button"
                  @click="onModelInput('productOptions', [])"
                >
                  {{ $t("common.reset") }}
                </div>
              </div>
            </div>
          </div>
        </ShiftLayoutAppearAnimation>
      </div>
      <hr class="product-form__separator separator">
      <div class="product-form__wrap">
        <div class="product-form__item product-form__item--center application-input-wrap">
          <div class="block-wrap block-wrap--offset-large-b">
            <div class="tw-flex tw-items-center tw-pr-4">
              <div class="tw-text-base tw-font-secondary">
                {{ $t("productForm.typeOfAppeal") }}:
              </div>
            </div>
            <label
              v-for="item in application_types"
              :key="item.value"
              class="switch-label"
            >
              <AppSwitch
                theme="secondary"
                tag="div"
                :error="!!getErrorMessage('cats')"
                :value="model.cats"
                :val="item.value"
                @input="onModelInput('cats', $event)"
              />
              <span>{{ item.name }}</span>
            </label>
          </div>
          <div class="product-form__error application-input-error">
            {{ getErrorMessage('cats') }}
          </div>
        </div>
        <div class="product-form__item application-input-wrap">
          <div class="application-input-title">
            {{ $t("productForm.parentMainCategory") }}
          </div>
          <AppSelectA
            :value="model.parent_id"
            :items="parsedProducts"
            class="application-input"
            placeholder="productForm.selectMainCategory"
            @input="onModelInput('parent_id', $event)"
          />
          <div class="product-form__error application-input-error" />
        </div>
        <div class="product-form__item">
          <div class="application-input-wrap mb-6-a">
            <div class="application-input-title">
              {{ $t("productForm.productNameUz") }}
            </div>
            <AppInput
              :value="model.title_uz"
              :error="!!getErrorMessage('title_uz')"
              class="application-input"
              type="text"
              size="large"
              @input="onModelInput('title_uz', $event)"
            />
            <div class="product-form__error application-input-error">
              {{ getErrorMessage('title_uz') }}
            </div>
          </div>
          <div class="application-input-wrap">
            <div class="application-input-title">
              {{ $t("productForm.productDescriptionUz") }}
            </div>
            <div
              class="app-editor"
              :class="{'app-editor--error': !!getErrorMessage('desc_uz')}"
            >
              <ckeditor
                :editor="editor"
                :value="model.desc_uz"
                :config="editorConfig"
                @input="onModelInput('desc_uz', $event)"
              />
            </div>
            <div class="product-form__error application-input-error">
              {{ getErrorMessage('desc_uz') }}
            </div>
          </div>
        </div>
        <div class="product-form__item">
          <div class="application-input-wrap mb-6-a">
            <div class="application-input-title">
              {{ $t("productForm.productNameRu") }}
            </div>
            <AppInput
              :value="model.title_ru"
              :error="!!getErrorMessage('title_ru')"
              class="application-input"
              type="text"
              size="large"
              @input="onModelInput('title_ru', $event)"
            />
            <div class="product-form__error application-input-error">
              {{ getErrorMessage('title_ru') }}
            </div>
          </div>
          <div class="application-input-wrap">
            <div class="application-input-title">
              {{ $t("productForm.productDescriptionRu") }}
            </div>
            <div
              class="app-editor"
              :class="{'app-editor--error': !!getErrorMessage('desc_ru')}"
            >
              <ckeditor
                :editor="editor"
                :value="model.desc_ru"
                :config="editorConfig"
                @input="onModelInput('desc_ru', $event)"
              />
            </div>
            <div class="product-form__error application-input-error">
              {{ getErrorMessage('desc_ru') }}
            </div>
          </div>
        </div>
      </div>
      <redmine-form
        v-model="redmine"
        @get-members="
          redmineFormLoading.responsible = true;
          redmine.tracker = null;
          fetchRedmineUsersAndGroups();
          clearMembers()
        "
        @get-items="fetchRedmineItems"
        @get-users="
          redmineFormLoading.responsibleFromGroup = true;
          fetchRedmineUsers();
          clearUsers()
        "
        :projects="projects"
        :responsible-list="responsibleList"
        :responsible-from-group="responsibleFromGroup"
        :loading="redmineFormLoading"
        default-opened
        hide-description
      />
    </AdminCard>
    <div class="d-flex justify-content-end">
      <AppButton
        class="mr-16"
        type="button"
        square
        theme="white-outline-narrow"
        tabindex="-1"
        @click="reset"
      >
        <AppIcon icon="delete" />
      </AppButton>
      <AppButton
        class="mr-16"
        type="button"
        theme="white-flat"
        @click="close"
        tabindex="-1"
      >
        {{ $t("common.cancel") }}
      </AppButton>
      <LoadingContent :loading="createLoading">
        <AppButton
          type="button"
          @click="save"
        >
          {{ $t("common.save") }}
        </AppButton>
      </LoadingContent>
    </div>
  </div>
</template>

<script>

import {get, post, put} from "../../helpers/api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import resize from "vue-resize-directive";
import AdminCard from "../../components/partials/AdminCard";
import AppInput from "../../components/partials/AppInputA";
import AppSelectA from "../../components/partials/AppSelectA";
import AppSelect from "../../components/partials/AppSelect";
import AppButton from "../../components/partials/AppButton";
import AppIcon from "../../components/partials/AppIcon";
import {ModalData, ValidatorData} from "../../services/ScriptPage";
import AppSwitch from "../../components/partials/AppSwitch";
import LoadingContent from "../../components/partials/LoadingContent";
import PageLoader from "../../components/partials/PageLoader";
import DragList from "../../components/partials/DragList";
import ShiftLayoutAppearAnimation from "../../components/partials/animation/ShiftLayoutAppearAnimation";
import AppTreeSelect from "@/components/partials/AppTreeSelect.vue";
import RedmineForm from "@/components/redmine-form.vue";
import {RedmineFormModel, PaginationList, RedmineFormLoading} from "@/components/redmine-form.vue";

const data = {
    title: [[["required"]], ""],
    title_uz: [[["required"]], ""],
    title_ru: [[["required"]], ""],
    desc_uz: [[["required"]], ""],
    desc_ru: [[["required"]], ""],
    parent_id: [[], 0],
    cats: [[["required"]], []],
    productOptions: [[], []],
};

export default {
    name: "ProductForm",
    components: {
        RedmineForm,
        AppTreeSelect,
        ShiftLayoutAppearAnimation,
        DragList, PageLoader, LoadingContent, AppSwitch, AppIcon, AppButton, AppSelect, AppSelectA, AppInput, AdminCard
    },
    directives: {
        resize,
    },
    data() {
        const dataEntries = Object.entries(data);
        const model = new ModalData(dataEntries.map(([key, value]) => [key, value[1]]));
        const validator = new ValidatorData(dataEntries.map(([key, value]) => [key, value[0]]), model);
        return {
            validator,
            modelController: model,
            model: model.getValues(),
            checkValid: false,
            errorMessages: {},
            products: [],
            application_types: [],
            options: [],
            editorConfig: {},
            editor: ClassicEditor,
            getLoading: false,
            createLoading: false,
            projects: new PaginationList(),
            responsibleList: new PaginationList(),
            responsibleFromGroup: new PaginationList(),
            redmine: new RedmineFormModel(),
            redmineFormLoading: new RedmineFormLoading()
        };
    },
    computed: {
        parsedProducts() {
            return [{name: "common.form.noMainCategory", value: 0}, ...this.products];
        },
        isEdit() {
            return !isNaN(this.$route.params.id);
        },
        optionsWithIds() {
            return this.options.reduce((acc, o) => {
                acc[o.value] = o;
                return acc;
            }, {});
        },
        selectedOptions() {
            return this.model.productOptions.map(o => this.optionsWithIds[o]);
        },
        filteredOptions() {
            return this.options.filter(o => !this.model.productOptions.includes(o.value));
        },
        optionActions() {
            return [
                {name: "productForm.optionActions.checkMark", icon: "check-double-square"},
                {name: "productForm.optionActions.credit", icon: "calculator-operations"},
                {name: "productForm.optionActions.deposit", icon: "calculator-bold"},
                {name: "productForm.optionActions.textField", icon: "text-field"},
                {name: "productForm.optionActions.fraud", icon: "check-double-square"},
            ];
        }
    },
    created() {
        this.modelController.on("update", this.onModelUpdate);
    },
    mounted() {
      this.loadData();
    },
    beforeDestroy() {
        this.modelController.off("update", this.onModelUpdate);
    },
    methods: {
        checkValidity() {
            if (!this.checkValid) return true;
            const valid = this.validator.validate();
            this.errorMessages = this.validator.getMessages();
            return valid;
        },
        getErrorMessage(key) {
            const first = this.errorMessages[key] && this.errorMessages[key][0];
            return first ? first : null;
        },
        onModelUpdate(keys) {
            keys.forEach(key => {
                this.$set(this.model, key, this.modelController.getValue(key));
            });
        },
        onModelInput(key, value) {
            this.modelController.update(key, value);
            this.checkValidity();
        },
        selectedOptionsInput(value) {
            const idx = this.model.productOptions.indexOf(value);
            const res = [...this.model.productOptions];
            res.splice(idx, 1);
            this.onModelInput("productOptions", res);
        },
        reset() {
            this.modelController.reset();
            this.validator.clear();
            this.$nextTick(() => {
                this.errorMessages = this.validator.getMessages();
            });
        },
        close() {
            this.$router.push("/products");
        },
        loadData() {
            get("/options").then((response) => {
                if (response.data.success) {
                    this.options = response.data.data.map((r) => ({
                        value: r.id,
                        name: r.name,
                        function: r.function,
                        action: r.action
                    }));
                }
            });
            get("/products?flat=1").then((response) => {
                if (response.data.success) {
                    this.products = response.data.data.map((r) => ({
                        value: r.id,
                        name: r.title
                    }));
                }
            });
            get("/app-types").then((response) => {
                if (response.data.success) {
                    this.application_types = response.data.data.map((r) => ({
                        value: r.id,
                        name: r.title
                    }));
                }
            });
            if (this.isEdit) {
                this.getLoading = true;
                get(`/products/${this.$route.params.id}`).then((res) => {
                    if (res.data.success) {
                        const d = res.data.data;
                        const data = {
                            title: d.title,
                            parent_id: d.parent_id,
                            cats: d.application_types?.map(item => item.id),
                            productOptions: d.options.map(item => item.id),
                        };

                        if(d.metadata?.redmine_project_id) {
                            this.redmine = {
                                project: +d.metadata.redmine_project_id,
                                tracker: +d.metadata.redmine_tracker_id,
                                responsible: +d.metadata.redmine_user_id,
                                responsibleFromGroup: null
                            };
                            this.redmineFormLoading.responsible = true;
                            this.fetchRedmineUsersAndGroups(d.metadata.redmine_user_id);
                            this.redmineFormLoading.projects = true;
                            this.fetchRedmineProjects(d.metadata.redmine_project_id);
                        } else {
                            this.fetchRedmineProjects();
                        }

                        let filterLang = function(sLang, translations) {
                            return translations.filter((item) => {
                                return (item.lang == sLang);
                            });
                        };
                        const uzLang = filterLang("uz", res.data.data.translations);
                        if (uzLang.length) {
                            data.title_uz = uzLang[0].title;
                            data.desc_uz = uzLang[0].description;
                        }
                        const ruLang = filterLang("ru", res.data.data.translations);
                        if (ruLang.length) {
                            data.title_ru = ruLang[0].title;
                            data.desc_ru = ruLang[0].description;
                        }

                        const dataEntries = Object.entries(data);

                        this.modelController.setNewDefaultValues(dataEntries.map(([key]) => [key, data[key]]));
                        this.validator.setNewModel(this.modelController);
                        this.modelController.reset();
                    }
                }).finally(() => {
                    this.getLoading = false;
                });
            } else {
                this.fetchRedmineProjects();
            }
        },
        save() {
            this.checkValid = true;
            if (!this.checkValidity()) return;

            this.createLoading = true;
            let method = post;
            let url = "/products";
            let message = "errors.failedToCreateTheme";
            if (this.isEdit) {
                method = put;
                url = `/products/${this.$route.params.id}`;
                message = "errors.failedToUpdateTheme";
            }

            const form = Object.assign({}, this.model, this.redmine?.project ? {
                redmine_project_id: this.redmine.project,
                redmine_tracker_id: this.redmine.tracker,
                redmine_user_id: this.redmine.responsibleFromGroup ? this.redmine.responsibleFromGroup : this.redmine.responsible
            }: {});

            form.productOptions = form.productOptions.map((id, position) => ({
                id,
                position: position + 1
            }));
            method(url, form).then((response) => {
                if (response.data.success) {
                    this.$router.push("/products");
                } else {
                    this.$store.commit("notifications/error", message);
                }
            }).finally(() => this.createLoading = false);
        },
        getOptionDescription(option) {
            return this.optionActions[option.action];
        },
        onOptionsSort(sorted) {
            this.onModelInput("productOptions", sorted.map(o => o.value));
        },
        async fetchRedmineProjects(id) {
            const query = Object.assign({}, {
                offset: this.projects.offset
            }, id ? {
                append_id: id
            } : {});

            try {
                const {data} = await get("redmine/projects?" + new URLSearchParams(query).toString());
                this.projects.fillFromData(data.data, "projects");

            } catch (e) {
              console.log(e);
            } finally {
                this.redmineFormLoading.projects = false;
            }
        },
        async fetchRedmineUsersAndGroups(id) {
            const query = Object.assign({}, {
                offset: this.responsibleList.offset
            }, id ? {
                append_id: id
            } : {});
            if (!this.redmine.project) {
                this.redmineFormLoading.responsible = false;
                return;
            }

            try {
                const {data} = await get("redmine/members/" + this.redmine.project + "?" + new URLSearchParams(query).toString());
                this.responsibleList.fillFromData(data.data, "members");

            } catch (e) {
            } finally {
                this.redmineFormLoading.responsible = false;
            }
        },
        async fetchRedmineUsers(id) {
            const query = Object.assign({}, {
                offset: this.responsibleFromGroup.offset,
                group_id: this.redmine.responsible
            }, id ? {
                append_id: id
            } : {});
            if (!this.redmine.responsible) {
                this.redmineFormLoading.responsibleFromGroup = false;
                return;
            }

            try {
                const {data} = await get("redmine/users" + "?" + new URLSearchParams(query).toString());
                this.responsibleFromGroup.fillFromData(data.data, "users");

            } catch (e) {
            } finally {
                this.redmineFormLoading.responsibleFromGroup = false;
            }
        },
        async fetchRedmineItems(name) {
            if (name === "projects") {
                this.projects.offset += 25;
                return this.fetchRedmineProjects();
            } else if (name === "responsibleList") {
                this.responsibleList.offset += 25;
                return this.fetchRedmineUsersAndGroups();
            } else if (name === "responsibleFromGroup") {
                this.responsibleFromGroup.offset += 25;
                return this.fetchRedmineUsers();
            }
        },
        clearMembers() {
            this.redmineFormLoading.responsible = true;
            this.responsibleList = new PaginationList();
            this.clearUsers();
        },
        clearUsers() {
            this.redmineFormLoading.responsibleFromGroup = true;
            this.responsibleFromGroup = new PaginationList();
        }
    }
};
</script>
<style lang="scss">
@import '@/assets/scss/variables';
@import '@/assets/scss/animation';
@import '@/assets/scss/components/filter-pill';

.product-form {
    &__wrap {
        align-items: flex-start;
        margin-bottom: 14px;
        margin-left: -20px;
        margin-top: -6px;
        display: flex;
        flex-wrap: wrap;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item {
        width: calc(50% - 20px);
        margin-left: 20px;
        margin-top: 6px;

        &--center {
            align-self: center;
        }

        &--large {
            width: calc(100% - 20px);
        }
    }

    &__error {
        height: 14px;
    }

    &__separator {
        margin-bottom: 24px;
    }

    &__options {
        height: auto !important;
        min-height: 48px !important;

        .ant-select-selection {
            padding-bottom: 0;
            min-height: 48px;
            height: auto;
        }

        .ant-select-selection__rendered {
            margin-bottom: 0;
            min-height: 48px;
            height: auto;
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }

    .drag-source {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            border-radius: 12px;
            border: 1px dashed var(--blue, #397AF5);
            background: var(--light-blue, #EDF2FF);
        }
    }
}

.drag-clone {
    .filter-pill {
        background-color: #fff;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.13);
    }
}

.app-editor {
    width: 100%;

    .ck-editor {
        --ck-border-radius: 4px;
        --ck-color-toolbar-border: #e1e1e1;
        --ck-color-base-border: #e1e1e1;
        width: 100%;
    }

    * {
        box-shadow: none !important;
    }

    &--error .ck-editor {
        --ck-color-toolbar-border: #e55353;
        --ck-color-base-border: #e55353;
        animation: error 0.5s 1 both;
    }
}

.switch-label {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.005em;
    color: #454142;
    font-family: $font-secondary;

    span {
        margin-left: 8px;
    }
}
</style>
